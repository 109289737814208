/* General modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-container {
  background: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 400px; /* Restrict the modal width */
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Header styles */
.modal-container h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #002855;
  text-align: center;
}

/* Input styles */
.location-input {
  width: 100%; /* Full width of the container */
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s ease;
  box-sizing: border-box; /* Prevent overflow */
}

.location-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
}

/* Search results styles */
.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px; /* Limit height to prevent overflow */
  overflow-y: auto; /* Add scroll for long lists */
  border: 1px solid #ddd; /* Optional: border around the dropdown */
  border-radius: 4px;
}

.search-result-item {
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.search-result-item:hover {
  background-color: #007bff;
  color: #ffffff;
}

.search-result-item:last-child {
  border-bottom: none;
}

/* Responsive design */
@media (max-width: 768px) {
  .modal-container {
    width: 95%; /* Adjust modal width for smaller screens */
    padding: 15px;
  }

  .modal-container h2 {
    font-size: 1.2rem;
  }

  .location-input {
    font-size: 0.9rem;
  }

  .search-result-item {
    font-size: 0.9rem;
  }
}
