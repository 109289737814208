.feedback-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.feedback-toggle {
  background-color: #ff5733;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.feedback-toggle:hover {
  background-color: #ff7733;
}

.feedback-form-container {
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 320px;
  padding: 20px;
  background-color: white;
  border: 2px solid #ff5733;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center; /* Center the form horizontally */
}

.feedback-form {
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width to take up the container space */
  align-items: center;
}

.feedback-form label {
  margin-bottom: 5px;
  font-size: 14px;
  /* align-self: flex-start;  */
}

.feedback-form input,
.feedback-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.feedback-form textarea {
  height: 100px;
  resize: none;
}

.submit-feedback {
  background-color: #ff5733;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Full width for the button */
}

.submit-feedback:hover {
  background-color: #ff7733;
}

.feedback-success {
  color: green;
  font-weight: bold;
  text-align: center;
}
