.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 50px;
}

.nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-link {
  font-size: 16px;
  font-weight: 600;
  color: #303c54;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 20px;
}

.auth-button-container {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.login-button {
  padding: 10px 20px;
  background-color: #002855;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}
