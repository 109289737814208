/* HeroSection.css */
.hero-section {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  overflow: hidden;
  background-color: #f2efe5; /* Background color or image */
  background-image: url('./assets/newspaper.jpg'); /* Add the path to your texture image */
  display: flex;
  flex-wrap: wrap; /* Helps images span across */
  align-content: space-between; /* Distributes images more evenly */
}

.scattered-image {
  position: absolute;
  width: 400px; /* Increased size for better background coverage */
  height: auto;
  transition: transform 0.3s ease;
  opacity: 0.85; /* Slight transparency for blending effect */
}

.scattered-image:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  z-index: 1;
}
