/* General layout */
/* Main page styles */

.auth-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding: 10px;
}

.login-button,
.logout-button,
.signup-button {
  background-color: #3cb043; /* Blue for both login and logout buttons */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}

.logout-button {
  background-color: #c0392b; /* Blue for both login and logout buttons */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}

.signup-button {
  background-color: #f4d03f; /* Blue for both login and logout buttons */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}

.login-button:hover,
.signup-button:hover,
.logout-button:hover {
  background-color: #3cb043; /* Slightly darker blue on hover */
}

.logout-button {
  background-color: #e74c3c; /* Red for logout */
}

.logout-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.App-header {
  background-color: #3cb043; /* Green for header background */
  color: white;
  text-align: center;
  padding: 20px;
}

.login-btn,
.logout-btn {
  background-color: #3cb043; /* Green button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.login-btn:hover,
.logout-btn:hover {
  background-color: #f4d03f; /* Yellow hover */
}

.error-msg {
  color: #c0392b; /* Red for error messages */
}

h1,
h2 {
  color: #3cb043; /* Green for titles */
}

/* Login page specific styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4d03f; /* Yellow background for login page */
}

.login-container h2 {
  color: #3cb043; /* Green title */
  margin-bottom: 20px;
}

.login-container button {
  background-color: #3cb043; /* Green button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px 0;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #f4d03f; /* Yellow hover */
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.App {
  text-align: center;
  background-color: #f4f4f4;
}

header {
  background-color: #3a3a3a;
  color: white;
  padding: 20px 0;
}

header h1 {
  margin: 0;
  font-size: 36px;
}

header p {
  margin: 0;
  font-size: 16px;
}

main {
  padding: 20px;
}

/* Map styles */
.map {
  margin-top: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
}

.grey-scaled .leaflet-tile {
  filter: grayscale(100%);
}

/* Popup form */
.leaflet-popup-content form {
  display: flex;
  flex-direction: column;
}

.leaflet-popup-content label {
  margin-top: 5px;
  font-size: 14px;
}

.leaflet-popup-content input[type='text'],
.leaflet-popup-content select {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.leaflet-popup-content button {
  background-color: #3a3a3a;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.leaflet-popup-content button:hover {
  background-color: #5a5a5a;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.social-login-buttons {
  width: 300px;
}

.social-login-buttons > button {
  margin-bottom: 15px;
}

.App-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #282c34;
  color: white;
}

.login-btn {
  background-color: #61dafb;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.login-btn:hover {
  background-color: #21a1f1;
}
