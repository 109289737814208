.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-container h2 {
  color: #002855;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.error-message {
  color: #d9534f; /* Red color for error messages */
  font-size: 14px;
  margin-bottom: 10px;
}

.success-message {
  color: #5cb85c; /* Green color for success messages */
  font-size: 14px;
  margin-bottom: 10px;
}

.modal-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-container input[type='email'] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.modal-container input[type='email']:focus {
  border-color: #002855; /* Blue border on focus */
}

.submit-button {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #002855; /* Primary blue color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #004080; /* Darker blue on hover */
}

.close-button {
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  color: #002855;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;
}

.close-button:hover {
  color: #004080;
}
